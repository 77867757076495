@tailwind base;
@tailwind components;
@tailwind utilities;
@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

body {
  overflow: hidden;
  margin: 0;
  padding: 0;
  font-family: "Metropolis", sans-serif;;
}

.boxShadow {
  -webkit-box-shadow: 5px 2px 15px 1px rgba(0,0,0,0.35); 
  box-shadow: 5px 2px 15px 1px rgba(0,0,0,0.35);
}

.boxShadowL {
  -webkit-box-shadow: 15px 0px 11px -4px rgba(0,0,0,0.47); 
  box-shadow: 15px 0px 11px -4px rgba(0,0,0,0.47);
}

.boxShadowT {
  -webkit-box-shadow: 5px 2px 15px 1px rgba(0,0,0,0.35); 
  box-shadow: 0px -2px 5px 1px rgba(0, 0, 0, 0.308);
}


.dark {
  background: var(--secondaryDarkColor);
}

body {
  background: #f3f1f2;
}

.ql-snow {
  border: 1px solid rgb(138, 138, 138) !important
}

.ql-picker-label {
  color: rgb(138, 138, 138) !important
}

.ql-formats {
  color: rgb(138, 138, 138) !important

}

.Right_To_Center {
  animation-name: Right_To_Center;
  animation-duration: 0.6s;

}

@keyframes Right_To_Center {
  from {
    transform: translateX(50px);
  }

  to {
   z-index: 900;

    transform: translateX(0);
  }
}

.Left_To_Center {
  animation-name: Left_To_Center;
  animation-duration: 0.6s;
}

@keyframes Left_To_Center {
  from {
    transform: translateX(-50px);
  }

  to {
    transform: translateX(0);
  }

}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type="number"] {
  -moz-appearance: textfield;
}