.menuHideDiv {
    transition: all ease 0.2s;
    z-index: 1;
}

.subMenu:hover .menuHideDiv {
    display: flex;    
    z-index: 1;
    transition: all ease 0.2s;
    transition-delay: 0.2s;
}

.menuHideDiv ul {
    opacity: 0;
    transform: translateY(40px);
    transition: all ease 0.2s;
    transition-delay: 0.2s;
    position: absolute;
    z-index: 2;
    left: 70px;
    width: 150px;
    overflow: hidden;
}

.menuHideDiv:hover ul{
    opacity: 1;
    transform: translateY(0px);

    left: 70px;
    width: 150px;
    overflow: initial;
    max-width: initial;
    transition: all ease 0.2s;
    max-height: initial;
    background-color: var(--bgPrimaryLight);
}

.menuSubList {
    transition: 0.2s;
}

.menuSubListEffect {
    transition: 0.2s;
}

.menuHoverEffect {
    transition: 0.2s;
    transition-delay: 0.2s;
}

.menuContainer::-webkit-scrollbar {

    overflow: overlay;
    width: 10px;
    height: 5px;
    
}

.menuContainer::-webkit-scrollbar-thumb  {

    background: rgba(90, 90, 90);
    
}

.menuContainer::-webkit-scrollbar-track  {

    background: rgba(0, 0, 0, 0.2);
    
}